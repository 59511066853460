<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        md="7"
        sm="12"
      >
        <!-- Selected Service Card -->
        <v-card>
          <v-card-title>
            <span class="headline">{{ $t('payment.selected-service') }}</span>
            <v-spacer />
          </v-card-title>
          <v-card-text>
            <v-row class="pt-5">
              <v-col cols="6">
                <v-select
                  v-model="subscriptionType"
                  color="secondary"
                  outlined
                  item-color="secondary"
                  :items="pricing"
                  :label="$t('payment.subscription')"
                  :item-text="$t('heading')"
                  item-value="code"
                  dense
                  return-object
                  @change="priceSelected"
                />
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="paymentType"
                  color="secondary"
                  outlined
                  item-color="secondary"
                  :items="pMode"
                  :label="$t('payment.mode')"
                  item-text="title"
                  item-value="val"
                  dense
                  :disabled="subscriptionType === null"
                  return-object
                  @change="priceMode"
                />
              </v-col>
            </v-row>
            <div class="display-2 text-right">
              Total amount: $
              <span class="text-h2 text-right">
                {{ totalAmount }}
              </span>
            </div>
            <div
              v-if="totalAmount !== 0 && paymentType.val === 0"
              class="display-1 text-right"
            >
              You save ~20%
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="5"
        sm="12"
      >
        <!-- Card Details -->
        <v-card width="500">
          <v-card-title>
            <span class="headline">{{ $t('payment.card-details') }}</span>
            <v-spacer />
            <v-img
              :src="require(`@/assets/cc-brands/${cc_brand}`)"
              max-width="64"
            />
          </v-card-title>
          <v-card-text>
            <v-container>
              <!-- New Form Section -->
              <validation-observer v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(payment)">
                  <v-row>
                    <v-col
                      cols="12"
                      class="pt-0"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        :rules="{ required: true, numeric: true, min:cardnumbermin, max:cardnumbermax }"
                        :name="$t('payment.card-number')"
                      >
                        <v-text-field
                          v-model="cardDetail.number"
                          class="mt-2 my-0 py-0"
                          :label="$t('payment.card-number')"
                          color="secondary"
                          outlined
                          dense
                          required
                          :error-messages="errors"
                          @input="creditCardType"
                        />
                      </validation-provider>
                    </v-col>
                    <v-col
                      cols="12"
                      class="pt-0"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                        :name="$t('payment.card-holder')"
                      >
                        <v-text-field
                          v-model="cardDetail.name"
                          class="mt-2 my-0 py-0"
                          :label="$t('payment.card-holder')"
                          color="secondary"
                          outlined
                          dense
                          required
                          counter="50"
                          :error-messages="errors"
                        />
                      </validation-provider>
                    </v-col>
                    <v-col
                      md="6"
                      sm="12"
                      class="pt-0"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                        :name="$t('payment.card-expire')"
                      >
                        <v-menu
                          ref="menu"
                          v-model="expireMenu"
                          :close-on-content-click="false"
                          :return-value.sync="date"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="computedDateFormatted"
                              :label="$t('payment.card-expire')"
                              readonly
                              outlined
                              dense
                              v-bind="attrs"
                              :error-messages="errors"
                              v-on="on"
                            />
                          </template>
                          <v-date-picker
                            v-model="date"
                            type="month"
                            :min="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                            no-title
                            scrollable
                          >
                            <v-spacer />
                            <v-btn
                              text
                              color="primary"
                              @click="expireMenu = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.menu.save(date)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                        <!-- <v-text-field
                          v-model="cardDetail.cardMonth"
                          class="my-0"
                          :label="$t('payment.card-expire')"
                          color="secondary"
                          outlined
                          dense
                          required
                          counter="25"
                          :error-messages="errors"
                        /> -->
                      </validation-provider>
                    </v-col>
                    <v-col
                      md="6"
                      sm="12"
                      class="pt-0"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        :rules="{ required: true, numeric: true, min: cvcmin, max: cvcmax }"
                        :name="$t('payment.card-code')"
                      >
                        <v-text-field
                          v-model="cardDetail.cvc"
                          class="my-0"
                          :label="$t('payment.card-code')"
                          color="secondary"
                          outlined
                          dense
                          required
                          counter="3"
                          :error-messages="errors"
                        />
                      </validation-provider>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-spacer />
                    <v-btn
                      color="success"
                      class="mr-3"
                      type="submit"
                      :disabled="totalAmount === 0"
                    >
                      {{ $t('payment.buy-subscription') }}
                    </v-btn>
                  </v-row>
                </form>
              </validation-observer>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- New SnackBarCard -->
    <snack-bar-card :snackbar="snackBar" />
  </v-container>
</template>

<script>
  // Components
  import { extend } from 'vee-validate'
  import { regex, min, max, numeric } from 'vee-validate/dist/rules'
  import { useSubscriptionStore } from '@/stores/SubscriptionStore'
  import SnackBarCard from '@/components/SnackbarCard'

  const subscription = useSubscriptionStore()

  // // Vee Validate.
  // extend('creditcard', creditcard)
  extend('regex', regex)
  extend('min', min)
  extend('max', max)
  extend('numeric', numeric)

  export default {
    name: 'SectionPlans',

    components: {
      SnackBarCard,
    },

    data: () => ({
      cardDetail: {
        number: '',
        name: '',
        cardMonth: null,
        cardYear: null,
        cvc: null,
      },
      date: null,
      activePicker: null,
      expireMenu: false,
      cc_brand: 'visa.svg',
      cardnumbermin: 13,
      cardnumbermax: 19,
      cvcmin: 0,
      cvcmax: 999,
      // pricingMode: [
      //   { title: 'landing-plans.price-yearly', val: 0 },
      //   { title: 'landing-plans.price-monthly', val: 1 },
      // ],
      paymentType: null,
      subscriptionType: null,
      amount: 0,

      // New snackbar notification
      snackBar: {
        type: 'success',
        mode: 'multi-line',
        direction: 'top center',
        timeout: 6000,
        title: '',
        text: '',
        visible: false,
      },
    }),

    computed: {
      pricing () {
        // console.log(subscription.pricing)
        // return translated names using .map()
        return subscription.pricing.map((data, i) => ({
          ...data,
          heading: this.$t(data.heading),
        }))
      },
      pMode () {
        return [
          { title: this.$t('landing-plans.price-yearly'), val: 0 },
          { title: this.$t('landing-plans.price-monthly'), val: 1 },
        ]
      },
      totalAmount () {
        // console.log(subscription.pricingType)
        let calc = 0
        if (this.paymentType === null || this.subscriptionType === null) {
          calc = 0
        } else {
          if (subscription.pricingType === 0) {
            calc = 79 * 12
          } else {
            calc = 99
          }
        }
        return calc
      },

      computedDateFormatted () {
        return this.formatDate(this.date)
      },
    },

    methods: {
      // https://locastic.com/blog/how-to-check-credit-card-type-with-javascript
      creditCardType (cc) {
        this.creditCardImage(cc)
        const amex = new RegExp('^3[47][0-9]{13}$')
        const visa = new RegExp('^4[0-9]{12}(?:[0-9]{3})?$')
        const cup1 = new RegExp('^62[0-9]{14}[0-9]*$')
        const cup2 = new RegExp('^81[0-9]{14}[0-9]*$')

        const mastercard = new RegExp('^5[1-5][0-9]{14}$')
        const mastercard2 = new RegExp('^2[2-7][0-9]{14}$')

        const disco1 = new RegExp('^6011[0-9]{12}[0-9]*$')
        const disco2 = new RegExp('^62[24568][0-9]{13}[0-9]*$')
        const disco3 = new RegExp('^6[45][0-9]{14}[0-9]*$')

        const diners = new RegExp('^3[0689][0-9]{12}[0-9]*$')
        const jcb = new RegExp('^35[0-9]{14}[0-9]*$')

        // console.log(cc)

        if (visa.test(cc)) {
          this.cardnumbers = 13
          return 'VISA'
        }
        if (amex.test(cc)) {
          return 'AMEX'
        }
        if (mastercard.test(cc) || mastercard2.test(cc)) {
          return 'MASTERCARD'
        }
        if (disco1.test(cc) || disco2.test(cc) || disco3.test(cc)) {
          return 'DISCOVER'
        }
        if (diners.test(cc)) {
          return 'DINERS'
        }
        if (jcb.test(cc)) {
          return 'JCB'
        }
        if (cup1.test(cc) || cup2.test(cc)) {
          return 'CHINA_UNION_PAY'
        }
        return undefined
      },

      // return image
      creditCardImage (cc) {
        const amex = new RegExp('^3[47]')
        const visa = new RegExp('^4')
        const cup1 = new RegExp('^62')
        const cup2 = new RegExp('^81')

        const mastercard = new RegExp('^5[1-5]')
        const mastercard2 = new RegExp('^2[2-7]')

        const disco1 = new RegExp('^6011')
        const disco2 = new RegExp('^62')
        const disco3 = new RegExp('^6[45]')

        const diners = new RegExp('^3[0689]')
        const jcb = new RegExp('^35')

        // console.log(cc)
        let image = ''
        if (visa.test(cc)) {
          // console.log('visa')
          image = 'visa'
        } else if (amex.test(cc)) {
          image = 'american-express'
        } else if (mastercard.test(cc) || mastercard2.test(cc)) {
          image = 'mastercard'
        } else if (disco1.test(cc) || disco2.test(cc) || disco3.test(cc)) {
          image = 'discover'
        } else if (diners.test(cc)) {
          image = 'diners'
        } else if (jcb.test(cc)) {
          image = 'jcb'
        } else if (cup1.test(cc) || cup2.test(cc)) {
          image = 'union-pay'
        } else {
          image = 'generic'
        }

        // console.log(image)
        this.cc_brand = `${image}.svg`
      },

      // priceType () {
      //   subscription.pricingType = this.pricingMode
      // },

      priceSelected (item) {
        // console.log(item)
        // console.log(this.subscriptionType)
      },

      priceMode (item) {
        // console.log(item)
        // console.log(this.paymentType)
        subscription.pricingType = item.val
        // console.log(subscription.pricingType)
      },

      translateMe (val) {
        // console.log(val)
        // console.log(this.$t(val))
        return this.$t(val)
      },

      payment () {
        this.snackBar = {
          type: 'info',
          mode: 'multi-line',
          title: this.totalAmount,
          text: JSON.stringify(this.cardDetail),
          visible: true,
        }
      },

      formatDate () {
        if (!this.date) {
          return null
        } else {
          const [year, month] = this.date.split('-')
          this.cardDetail.cardYear = year
          this.cardDetail.cardMonth = month
          return `${month}/${year.substr(2, 2)}`
        }
      },
    },
  }
</script>
